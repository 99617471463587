<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      @click:outside="closeModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :maxWidth="662"
    >
      <div class="how-it-works-modal">
        <h5 class="text-h5 text-center mb-5 font-weight-regular gray--text text--darken-3">
          {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.title") }}
        </h5>

        <div class="how-it-works-modal-section mb-4">
          <img src="@/assets/images/deposit.png" class="mr-4" alt="" />
          <div>
            <span class="d-block how-it-works-modal-section-title gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.deposit.title") }}
            </span>
            <span class="d-block gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.deposit.description") }}
            </span>
          </div>
        </div>

        <div class="how-it-works-modal-section mb-4">
          <img src="@/assets/images/measurement.png" class="mr-4" alt="" />
          <div>
            <span class="d-block how-it-works-modal-section-title gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.measurement.title") }}
            </span>
            <span class="d-block gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.measurement.description") }}
            </span>
          </div>
        </div>

        <div class="how-it-works-modal-section mb-4">
          <img src="@/assets/images/fabrication.png" class="mr-4" alt="" />
          <div>
            <span class="d-block how-it-works-modal-section-title gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.fabrication.title") }}
            </span>
            <span class="d-block gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.fabrication.description") }}
            </span>
          </div>
        </div>

        <div class="how-it-works-modal-section mb-4">
          <img src="@/assets/images/installation.png" class="mr-4" alt="" />
          <div>
            <span class="d-block how-it-works-modal-section-title gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.installation.title") }}
            </span>
            <span class="d-block gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.howItWorksModal.sections.installation.description") }}
            </span>
          </div>
        </div>

        <div class="mt-5 d-flex align-center justify-center">
          <v-btn @click="closeModal" depressed class="rounded-0" color="primary" large>
            {{ $t("defaults.ok") }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    isOpen: Boolean
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>