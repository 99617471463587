import qs from "qs";

export function getAttachmentPreviewURL({ genFileName }, width = null, height = null) {
  const queryString = qs.stringify({width, height}, { skipNulls: true });
  return `${process.env.VUE_APP_BACKEND_URL}/api/v1/images/${genFileName}/preview?${queryString}`;
}

export function preloadAttachment(attachment, width = null, height = null) {
  return new Promise((resolve) => {
    const attachmentPreviewURL = getAttachmentPreviewURL(attachment, width, height);
    const image = new Image();
    image.onload = () => {
      resolve();
    };
    image.src = attachmentPreviewURL;
  });
}

export function preloadSelectedStoneImages(selectedStone) {
  preloadAttachment(selectedStone.slabImage).then(() => {});
  preloadAttachment(selectedStone.closeUpImage).then(() => {});
  preloadAttachment(selectedStone.vignetteImage).then(() => {});
}

export function preloadVisualizerImages(visualizerImages) {
  for (const visualizerImage of visualizerImages) {
    preloadAttachment(visualizerImage.image).then(() => {});
  }
}
