import { preloadAttachment } from "@/utils/attachment";

const state = {
  kitchenLayoutTypes: [],
  selectedKitchenLayoutType: null,
  sqft: null,
  knowsSqft: null,
  sides: {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
    f: null,
    g: null,
    h: null
  }
};

const getters = {};

const actions = {
  saveSqft({ commit }, sqft) {
    commit("SET_SQFT", { sqft });
  },
  saveKnowsSqft({ commit }, knowsSqft) {
    commit("SET_KNOWS_SQFT", { knowsSqft });
  },
  saveSides({ commit }, sides) {
    commit("SET_SIDES", { sides });
  },
  resetSqftFields({ commit }) {
    commit("SET_SQFT", { sqft: null });
    commit("SET_KNOWS_SQFT", { knowsSqft: null });
    commit("SET_SIDES", {
      sides: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null
      }
    });
  },
  saveSelectedKitchenLayoutType({ commit, dispatch, state }, id) {
    if (state.selectedKitchenLayoutType && id !== state.selectedKitchenLayoutType.id) {
      dispatch("resetSqftFields");
    }
    const selectedKitchenLayoutType = state.kitchenLayoutTypes.find(
      (kitchenLayout) => kitchenLayout.id === id
    );
    commit("SET_SELECTED_KITCHEN_LAYOUT_TYPE", { selectedKitchenLayoutType });
  },
  async getKitchenLayoutTypes({ commit }) {
    const kitchenLayoutTypes = await window.API.fetchActiveKitchenLayoutTypes();
    for (const kitchenLayoutType of kitchenLayoutTypes) {
      await preloadAttachment(kitchenLayoutType.image);
      preloadAttachment(kitchenLayoutType.countertopSquareFootageCalculatorImage);
    }
    commit("SET_KITCHEN_LAYOUT_TYPES", { kitchenLayoutTypes });
  }
};

const mutations = {
  SET_SQFT(state, payload) {
    state.sqft = Number(payload.sqft);
  },
  SET_KNOWS_SQFT(state, payload) {
    state.knowsSqft = payload.knowsSqft;
  },
  SET_SELECTED_KITCHEN_LAYOUT_TYPE(state, payload) {
    state.selectedKitchenLayoutType = payload.selectedKitchenLayoutType;
  },
  SET_KITCHEN_LAYOUT_TYPES(state, payload) {
    state.kitchenLayoutTypes = payload.kitchenLayoutTypes;
  },
  SET_SIDES(state, payload) {
    state.sides = {
      ...payload.sides
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
