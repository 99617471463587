import { preloadAttachment } from "@/utils/attachment";

const state = {
  floorColors: [],
  cabinetColors: [],
  selectedCabinetColor: null,
  selectedFloorColor: null
};

const getters = {};

const actions = {
  saveSelectedFloorColor({ commit, state }, id) {
    const selectedFloorColor = state.floorColors.find(
      (floorColor) => floorColor.id === id
    );
    commit("SET_SELECTED_FLOOR_COLOR", { selectedFloorColor });
  },
  async getFloorColors({ commit }) {
    const floorColors = await window.API.fetchActiveFloorColors();
    for (const floorColor of floorColors) {
      await preloadAttachment(floorColor.image);
    }
    commit("SET_FLOOR_COLORS", { floorColors });
  },
  saveSelectedCabinetColor({ commit, state }, id) {
    const selectedCabinetColor = state.cabinetColors.find(
      (cabinetColor) => cabinetColor.id === id
    );
    commit("SET_SELECTED_CABINET_COLOR", { selectedCabinetColor });
  },
  async getCabinetColors({ commit }) {
    const cabinetColors = await window.API.fetchActiveCabinetColors();
    for (const cabinetColor of cabinetColors) {
      await preloadAttachment(cabinetColor.image);
    }
    commit("SET_CABINET_COLORS", { cabinetColors });
  }
};

const mutations = {
  SET_SELECTED_FLOOR_COLOR(state, payload) {
    state.selectedFloorColor = payload.selectedFloorColor;
  },
  SET_FLOOR_COLORS(state, payload) {
    state.floorColors = payload.floorColors;
  },
  SET_SELECTED_CABINET_COLOR(state, payload) {
    state.selectedCabinetColor = payload.selectedCabinetColor;
  },
  SET_CABINET_COLORS(state, payload) {
    state.cabinetColors = payload.cabinetColors;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
