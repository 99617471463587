import BaseNotification from "./BaseNotification.vue";
import BaseModal from "./BaseModal.vue";
import BaseLoading from "./BaseLoading.vue";
import OrderSummary from "./create-order/OrderSummary.vue";
import HowItWorksModal from "./create-order/HowItWorksModal.vue";

const components = [
  { name: "s-notification", component: BaseNotification },
  { name: "s-modal", component: BaseModal },
  { name: "s-loading", component: BaseLoading },
  { name: "s-order-summary", component: OrderSummary },
  { name: "s-how-it-works-modal", component: HowItWorksModal }
];

export default components;
