const state = {
  fullName: null,
  emailAddress: null,
  phoneNumber: null
};

const getters = {
  customerInfoFilled(state) {
    return state.fullName && state.emailAddress;
  }
};

const actions = {
  saveCustomerInfo({ commit }, { fullName, emailAddress, phoneNumber }) {
    commit("SET_FULL_NAME", { fullName });
    commit("SET_EMAIL_ADDRESS", { emailAddress });
    commit("SET_PHONE_NUMBER", { phoneNumber });
  }
};

const mutations = {
  SET_FULL_NAME(state, payload) {
    state.fullName = payload.fullName;
  },
  SET_EMAIL_ADDRESS(state, payload) {
    state.emailAddress = payload.emailAddress;
  },
  SET_PHONE_NUMBER(state, payload) {
    state.phoneNumber = payload.phoneNumber;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
