import DesignCountertopLayout from "@/layouts/DesignCountertopLayout.vue";
import OrderCountertopLayout from "@/layouts/OrderCountertopLayout.vue";

const routes = [
  {
    path: "/",
    redirect: "/design-countertop/kitchen-layout/kitchen-shape"
  },
  {
    path: "/design-countertop",
    component: DesignCountertopLayout,
    meta: {
      requiresAuth: false,
      permission: "*"
    },
    children: [
      {
        path: "kitchen-layout/kitchen-shape",
        name: "kitchenLayout/kitchenShape",
        component: () => import("@/views/create-order/kitchen-layout/KitchenShape.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "kitchen-layout/sqft",
        name: "kitchenLayout/sqft",
        component: () => import("@/views/create-order/kitchen-layout/Sqft.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "colors/floor",
        name: "colors/floor",
        component: () => import("@/views/create-order/colors/FloorColor.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "colors/cabinet",
        name: "colors/cabinet",
        component: () => import("@/views/create-order/colors/CabinetColor.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "visualization",
        name: "visualization",
        component: () => import("@/views/create-order/visualization/Index.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      }
    ]
  },
  {
    path: "/order-countertop",
    component: OrderCountertopLayout,
    meta: {
      requiresAuth: false,
      permission: "*"
    },
    children: [
      {
        path: "kitchen-details",
        name: "kitchenDetails",
        component: () => import("@/views/create-order/KitchenDetails.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "address",
        name: "address",
        component: () => import("@/views/create-order/Address.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      },
      {
        path: "deposit",
        name: "deposit",
        component: () => import("@/views/create-order/Deposit.vue"),
        meta: {
          requiresAuth: false,
          permission: "*"
        }
      }
    ]
  },
  {
    path: "/order-countertop/schedule-measurement",
    name: "scheduleMeasurement",
    component: () => import("@/views/create-order/ScheduleMeasurement.vue"),
    meta: {
      requiresAuth: false,
      permission: "*"
    }
  }
];

export default routes;
