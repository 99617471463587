<template>
  <div class="stone-details border-all mt-6">
    <div class="stone-details-heading text-center py-3 px-5">
      <span class="gray--text text--darken-3">{{ imageTitles[currentCarouselImageKey] }}</span>
    </div>
    <div class="stone-details-body pt-3 pb-4 px-5 text-subtitle-1 gray--text text--darken-3">
      {{ selectedStone[currentCarouselImageKey].description }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      imageTitles: {
        visualizerImage: this.$t("createOrder.visualization.stoneDetails.visualizerImageTitle"),
        slabImage: this.$t("createOrder.visualization.stoneDetails.slabImageTitle"),
        closeUpImage: this.$t("createOrder.visualization.stoneDetails.closeUpImageTitle"),
        vignetteImage: this.$t("createOrder.visualization.stoneDetails.vignetteImageTitle")
      }
    };
  },
  computed: {
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone,
      currentCarouselImageKey: state => state.currentCarouselImageKey
    })
  }
};
</script>
