import { preloadAttachment, preloadSelectedStoneImages, preloadVisualizerImages } from "@/utils/attachment";
import { useConstants } from "@/plugins/constants";

const carouselImageKeys = ["visualizerImage", "closeUpImage", "vignetteImage", "slabImage"];

const state = {
  stones: [],
  visualizerImages: [],
  selectedStone: null,
  currentCarouselImageIndex: 0,
  currentCarouselImageKey: carouselImageKeys[0]
};

const getters = {};

const actions = {
  async getVisualizerImages({ commit, rootState }) {
    const { selectedFloorColor, selectedCabinetColor } = rootState.Colors;
    const { selectedKitchenLayoutType } = rootState.KitchenLayout;
    if (!selectedFloorColor || !selectedCabinetColor || !selectedKitchenLayoutType) {
      return;
    }

    const visualizerImages = await window.API.fetchVisualizerImages({
      floorColorId: selectedFloorColor.id,
      cabinetColorId: selectedCabinetColor.id,
      kitchenLayoutTypeId: selectedKitchenLayoutType.id
    });

    commit("SET_VISUALIZER_IMAGES", { visualizerImages });
    preloadVisualizerImages(visualizerImages);
  },
  saveSelectedStone({ commit, state }, id) {
    const selectedStone = state.stones.find(
      (stone) => stone.id === id
    );
    commit("SET_SELECTED_STONE", { selectedStone });
    preloadSelectedStoneImages(selectedStone);
  },
  saveCurrentCarouselImageIndex({ commit }, index) {
    commit("SET_CURRENT_CAROUSEL_IMAGE_INDEX", { currentCarouselImageIndex: index });
    const currentCarouselImageKey = carouselImageKeys[index];
    commit("SET_CURRENT_CAROUSEL_IMAGE_KEY", { currentCarouselImageKey });
  },
  async getStones({ commit, state }) {
    if (state.visualizerImages.length === 0) {
      return;
    }

    const stones = await window.API.fetchActiveStones();
    for (let i = 0; i < stones.length; i++) {
      const stone = stones[i];
      stone.visualizerImage = state.visualizerImages.find(visualizerImage => visualizerImage.stoneId === stone.id).image;
      preloadAttachment(stone.slabImage, null, 108);
    }

    const { DEFAULT_STONE_NAME } = useConstants();
    const selectedStone = stones.find(stone => stone.name === DEFAULT_STONE_NAME);
    commit("SET_STONES", { stones });
    preloadSelectedStoneImages(selectedStone);

    commit("SET_SELECTED_STONE", { selectedStone });
    preloadAttachment(selectedStone.visualizerImage);
  }
};

const mutations = {
  SET_VISUALIZER_IMAGES(state, payload) {
    state.visualizerImages = payload.visualizerImages;
  },
  SET_SELECTED_STONE(state, payload) {
    state.selectedStone = payload.selectedStone;
  },
  SET_STONES(state, payload) {
    state.stones = payload.stones;
  },
  SET_CURRENT_CAROUSEL_IMAGE_INDEX(state, payload) {
    state.currentCarouselImageIndex = payload.currentCarouselImageIndex;
  },
  SET_CURRENT_CAROUSEL_IMAGE_KEY(state, payload) {
    state.currentCarouselImageKey = payload.currentCarouselImageKey;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
