export async function fetchActiveKitchenLayoutTypes() {
  const response = await window.axios.get("/kitchen-layout-types/active");
  return response.data;
}

export async function fetchActiveFloorColors() {
  const response = await window.axios.get("/colors/floor/active");
  return response.data;
}

export async function fetchActiveCutoutTypes() {
  const response = await window.axios.get("/cutout-types/active");
  return response.data;
}

export async function fetchAllStates() {
  const response = await window.axios.get("/states/all");
  return response.data;
}

export async function fetchActiveCabinetColors() {
  const response = await window.axios.get("/colors/cabinet/active");
  return response.data;
}

export async function fetchActiveStones() {
  const response = await window.axios.get("/stones/active");
  return response.data;
}

export async function fetchVisualizerImages({ floorColorId, cabinetColorId, kitchenLayoutTypeId }) {
  const response = await window.axios.get("/kitchen-visualizer-images", {
    params: { floorColorId, cabinetColorId, kitchenLayoutTypeId }
  });
  return response.data;
}

export async function createOrder({
                                    kitchenLayoutTypeId,
                                    floorColorId,
                                    cabinetColorId,
                                    stoneId,
                                    totalCountertopSquareFootage,
                                    hasOldCountertopRemoval,
                                    customer,
                                    jobsiteAddress,
                                    cutouts
                                  }) {
  const response = await window.axios.post("/orders", {
    kitchenLayoutTypeId,
    floorColorId,
    cabinetColorId,
    stoneId,
    totalCountertopSquareFootage,
    hasOldCountertopRemoval,
    customer,
    jobsiteAddress,
    cutouts
  });
  return response.data;
}

export async function createCustomer({ fullName, emailAddress, phoneNumber }) {
  const response = await window.axios.post("/customers", {
    fullName,
    emailAddress,
    phoneNumber
  });
  return response.data;
}

export async function applyCoupon({ orderId, couponCode }) {
  const response = await window.axios.post(`/orders/${orderId}/apply-coupon`, {
    orderId, couponCode
  });
  return response.data;
}
