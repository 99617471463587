import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import Steps from "./modules/steps";
import KitchenLayout from "./modules/kitchenLayout";
import Colors from "./modules/colors";
import Customer from "./modules/customer";
import Visualization from "./modules/visualization";
import KitchenDetails from "./modules/kitchenDetails";
import Deposit from "@/store/modules/deposit";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  modules: {
    Steps,
    KitchenLayout,
    Colors,
    Customer,
    Visualization,
    KitchenDetails,
    Deposit
  },
  plugins: [vuexLocal.plugin]
});
