<template>
  <div class="app-layout">
    <header class="app-header">
      <v-row>
        <v-col class="d-flex align-center justify-space-between">
          <img src="@/assets/images/stonely-logo.svg" alt="Stonely logo" />
          <v-btn icon v-if="$vuetify.breakpoint.smAndDown">
            <v-icon class="blue-grey--text text--darken-2" @click="toggleHowItWorksModal">
              mdi-information-outline
            </v-icon>
          </v-btn>
          <v-btn v-else @click="toggleHowItWorksModal" depressed outlined class="rounded-0">
            {{ $t("createOrder.layouts.orderCountertop.actions.howItWorks") }}
          </v-btn>
        </v-col>
      </v-row>
    </header>
    <v-main>
      <v-row>
        <v-col lg="9" md="8">
          <div class="py-md-4 py-3 text-center">
            <h1 class="text-h6 text-md-h5 font-weight-regular gray--text text--darken-3">
              {{ $t("createOrder.layouts.orderCountertop.title") }}
            </h1>
          </div>
          <v-stepper v-model="currentStepIndex" class="elevation-0" alt-labels>
            <v-stepper-header>
              <template v-for="(step, index) in orderSteps">
                <v-stepper-step
                  :complete="step.completed"
                  :key="`step-${step.path}`"
                  :step="index + 1"
                >
                  {{ step.label }}
                </v-stepper-step>
                <v-divider
                  v-if="!isLastStep(index)"
                  :key="`divider-${step.path}`"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
          <router-view />
        </v-col>
        <v-col lg="3" md="3" class="d-md-flex d-none">
          <div class="app-right-pane">
            <s-order-summary v-if="selectedStone" />
            <s-apply-coupon v-if="isDepositStepActive && !appliedCoupon" />
          </div>
        </v-col>
      </v-row>
    </v-main>

    <div @click="toggleMobileOrderSummary" :class="['mobile-order-summary', { 'expanded': !mobileOrderSummaryCollapsed }]">
      <div role="button" class="clickable-area">
        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="6" viewBox="0 0 86 6" fill="none">
          <path d="M3 3H83" stroke="#BDBDBD" stroke-width="5" stroke-linecap="round" />
        </svg>
      </div>
      <s-order-summary />
    </div>

    <v-overlay @click="toggleMobileOrderSummary" :z-index="999" :value="!mobileOrderSummaryCollapsed" />

    <s-how-it-works-modal @close="toggleHowItWorksModal" :isOpen="showHowItWorksModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApplyCoupon from "@/components/create-order/ApplyCoupon.vue";

export default {
  components: {
    "s-apply-coupon": ApplyCoupon
  },
  data: () => ({
    mobileOrderSummaryCollapsed: true,
    currentStepIndex: 1,
    showHowItWorksModal: false
  }),
  computed: {
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone
    }),
    ...mapState("Steps", {
      orderSteps: (state) => state.orderSteps,
      designSteps: (state) => state.designSteps
    }),
    ...mapState("Deposit", {
      appliedCoupon: state => state.appliedCoupon
    }),
    isDepositStepActive() {
      return this.$route.name === "deposit";
    }
  },
  mounted() {
    if (!this.isDesignStepsCompleted()) {
      return this.redirectToLastDesignStep();
    }
    this.setCurrentStepFromRoute();
  },
  updated() {
    const currentPath = this.$route.path;
    const stepIndex = this.orderSteps.findIndex((step) => step.path === currentPath);
    this.currentStepIndex = stepIndex + 1;

    const isHowItWorksModalShown = localStorage.getItem(this.$constants.IS_HOW_IT_WORKS_MODAL_SHOWN_STORAGE_KEY);
    const isAddressStepActive = this.$route.name === "address";
    if (isAddressStepActive && !isHowItWorksModalShown) {
      this.showHowItWorksModal = true;
      localStorage.setItem(this.$constants.IS_HOW_IT_WORKS_MODAL_SHOWN_STORAGE_KEY, "true");
    }
  },
  methods: {
    toggleMobileOrderSummary() {
      this.mobileOrderSummaryCollapsed = !this.mobileOrderSummaryCollapsed;
    },
    isLastStep(index) {
      return index === this.orderSteps.length - 1;
    },
    setCurrentStepFromRoute() {
      const currentPath = this.$route.path;
      const stepIndex = this.orderSteps.findIndex((step) => step.path === currentPath);

      const firstAvailableStep = this.findFirstAvailableStep(stepIndex);
      this.currentStepIndex = firstAvailableStep.index + 1;
      this.$router.push(firstAvailableStep.path).catch(() => {});
    },
    findFirstAvailableStep(startIndex) {
      for (let i = startIndex; i >= 1; i--) {
        const step = this.orderSteps[i];
        const prevStep = this.orderSteps[i - 1];
        if (prevStep.completed) {
          return {
            index: i,
            path: step.path
          };
        }
      }
      return {
        index: 0,
        path: this.orderSteps[0].path
      };
    },
    isDesignStepsCompleted() {
      const lastDesignStep = this.designSteps[this.designSteps.length - 1];
      return lastDesignStep.completed;
    },
    redirectToLastDesignStep() {
      const lastDesignStep = this.designSteps[this.designSteps.length - 1];
      this.$router.push(lastDesignStep.path);
    },
    toggleHowItWorksModal() {
      if (this.showHowItWorksModal === false) {
        localStorage.setItem(this.$constants.IS_HOW_IT_WORKS_MODAL_SHOWN_STORAGE_KEY, "true");
      }
      this.showHowItWorksModal = !this.showHowItWorksModal;
    }
  }
};
</script>
