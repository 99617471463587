const state = {
  appliedCoupon: null
};

const getters = {};

const actions = {
  saveAppliedCoupon({ commit }, appliedCoupon) {
    commit("SET_APPLIED_COUPON", { appliedCoupon });
  }
};

const mutations = {
  SET_APPLIED_COUPON(state, payload) {
    state.appliedCoupon = payload.appliedCoupon;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
