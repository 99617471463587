const state = {
  cutoutTypes: [],
  selectedCutouts: [],
  hasOldCountertopRemoval: null
};

const getters = {};

const actions = {
  saveSelectedCutouts({ commit }, selectedCutouts) {
    commit("SET_SELECTED_CUTOUTS", { selectedCutouts });
  },
  resetSelectedCutouts({ commit }) {
    commit("SET_SELECTED_CUTOUTS", { selectedCutouts: [] });
  },
  saveHasOldCountertopRemoval({ commit }, hasOldCountertopRemoval) {
    commit("SET_HAS_OLD_COUNTERTOP_REMOVAL", { hasOldCountertopRemoval });
  },
  async getCutoutTypes({ commit }) {
    const cutoutTypes = await window.API.fetchActiveCutoutTypes();
    commit("SET_CUTOUT_TYPES", { cutoutTypes });
  }
};

const mutations = {
  SET_SELECTED_CUTOUTS(state, payload) {
    state.selectedCutouts = payload.selectedCutouts;
  },
  SET_CUTOUT_TYPES(state, payload) {
    state.cutoutTypes = payload.cutoutTypes;
  },
  SET_HAS_OLD_COUNTERTOP_REMOVAL(state, payload) {
    state.hasOldCountertopRemoval = payload.hasOldCountertopRemoval;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
