const OLD_COUNTERTOP_REMOVAL_FEE = 250;
const DEPOSIT_AMOUNT_FACTOR = 0.1;

const DiscountType = {
  FIXED: 1,
  PERCENTAGE: 2
};

export const usdCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export function getOldCountertopRemovalFee() {
  return OLD_COUNTERTOP_REMOVAL_FEE;
}

export function calculateTotalAmount(selectedStone, sqft, hasOldCountertopRemoval, selectedCutouts) {
  let total = selectedStone.pricePerSquareFoot * sqft;

  if (hasOldCountertopRemoval) {
    total += OLD_COUNTERTOP_REMOVAL_FEE;
  }

  for (let cutout of selectedCutouts) {
    total += (cutout.pricePerCutout * cutout.quantity);
  }

  return total;
}

export function calculateDepositAmount(totalAmount) {
  return totalAmount * DEPOSIT_AMOUNT_FACTOR;
}

export function calculateDepositAmountAfterDiscount(totalAmount, coupon) {
  const totalAmountAfterDiscount = calculateTotalAmountAfterDiscount(totalAmount, coupon);
  return calculateDepositAmount(totalAmountAfterDiscount);
}

export function calculateTotalAmountAfterDiscount(totalAmount, coupon) {
  const { discountType, discountValue } = coupon;
  let totalAmountAfterDiscount = totalAmount;

  if (discountType === DiscountType.FIXED) {
    totalAmountAfterDiscount -= discountValue;
  } else if (discountType === DiscountType.PERCENTAGE) {
    totalAmountAfterDiscount -= totalAmount * discountValue / 100;
  }

  return totalAmountAfterDiscount;
}

export function getTotalAmountAfterDiscountText(totalAmount, coupon) {
  const { discountType, discountValue } = coupon;
  const totalAmountAfterDiscount = usdCurrencyFormatter.format(calculateTotalAmountAfterDiscount(totalAmount, coupon));
  let discount = "";

  if (discountType === DiscountType.FIXED) {
    discount = `$${discountValue}`;
  } else if (discountType === DiscountType.PERCENTAGE) {
    discount = `${discountValue}%`;
  }

  return `${totalAmountAfterDiscount} (${discount} OFF)`;
}
