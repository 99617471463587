import axios from "axios";
import { eventBus } from "@/main";
import { i18n } from "@/plugins/i18n";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + "/api/v1",
  timeout: 60 * 1000
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    eventBus.$emit("notification", {
      type: "error",
      message: error.message || i18n.t("defaults.alerts.couldNotFetch")
    });
  });

export default axiosClient;
