<template>
  <div class="mt-md-6 mt-0 px-5 py-5 border-all">
    <v-form @submit.prevent="handleFormSubmit" v-model="formIsValid" ref="applyCouponForm" lazy-validation>
      <label class="text-subtitle-2 gray--text text--darken-2">
        {{ $t("createOrder.deposit.applyCoupon.form.couponCode.label") }}
      </label>
      <v-text-field
        class="mb-2 mt-2"
        v-model="form.couponCode"
        :rules="rules.couponCode"
        :placeholder="$t('createOrder.deposit.applyCoupon.form.couponCode.placeholder')"
        :error-messages="errorMessage"
        required
        outlined
        single-line
        hide-details="auto"
      />
      <v-btn
        large
        type="submit"
        elevation="0"
        color="primary"
        class="rounded-0 w-100 mt-3"
        :loading="submitting"
        :disabled="!form.couponCode || !formIsValid"
      >
        {{ $t("createOrder.deposit.applyCoupon.actions.apply") }}
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  data() {
    return {
      formIsValid: false,
      submitting: false,
      errorMessage: null,
      form: {
        couponCode: null
      },
      rules: {
        couponCode: [v => !!v || this.$t("createOrder.deposit.applyCoupon.form.couponCode.rules.required")]
      }
    };
  },
  watch: {
    ["form.couponCode"]() {
      this.errorMessage = null;
    }
  },
  methods: {
    ...mapActions("Deposit", ["saveAppliedCoupon"]),
    async handleFormSubmit() {
      const isValid = this.$refs.applyCouponForm.validate();
      if (isValid) {
        this.errorMessage = null;
        this.submitting = true;
        try {
          const { couponCode } = this.form;
          const orderId = localStorage.getItem(this.$constants.ORDER_ID_STORAGE_KEY);

          const { resultMessage, coupon } = await window.API.applyCoupon({ orderId, couponCode });

          if (coupon !== null) {
            this.saveAppliedCoupon(coupon);
            eventBus.$emit("notification", {
              type: "success",
              message: this.$t("createOrder.deposit.applyCoupon.alert.appliedSuccessfully")
            });
          } else if (resultMessage) {
            this.errorMessage = resultMessage;
          }
        } finally {
          this.$refs.applyCouponForm.resetValidation();
          this.submitting = false;
        }
      }
    }
  }
};
</script>
