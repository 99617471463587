import Vue from "vue";

let instance;
export const useConstants = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        IS_HOW_IT_WORKS_MODAL_SHOWN_STORAGE_KEY: "IS_HOW_IT_WORKS_MODAL_SHOWN",
        DEPOSIT_STRIPE_PAYMENT_CLIENT_SECRET_STORAGE_KEY: "DEPOSIT_STRIPE_PAYMENT_CLIENT_SECRET",
        ORDER_ID_STORAGE_KEY: "ORDER_ID",
        OUTLET_CUTOUT_NAME: "electric-outlet",
        DEFAULT_STONE_NAME: "calacatta-laza"
      };
    }
  });

  return instance;
};

export const Constants = {
  install(Vue) {
    Vue.prototype.$constants = useConstants();
  }
};
