<template>
  <div class="contact-details px-5 py-5 border-all">
    <div class="mb-3 grey--text text--darken-3">
      {{ $t("createOrder.visualization.contactDetails.title") }}
    </div>
    <v-form @submit.prevent="handleFormSubmit" v-model="formIsValid" ref="contactDetailsForm" lazy-validation>
      <v-row>
        <v-col cols="12" class="mb-3">
          <label class="d-inline-block text-subtitle-2 gray--text text--darken-2 mb-2">
            {{ $t("createOrder.visualization.contactDetails.form.fullName.label") }}
          </label>
          <v-text-field
            single-line
            v-model="form.fullName"
            :rules="rules.fullName"
            required
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-3">
          <label class="d-inline-block text-subtitle-2 gray--text text--darken-2 mb-2">
            {{ $t("createOrder.visualization.contactDetails.form.emailAddress.label") }}
          </label>
          <v-text-field
            single-line
            v-model="form.emailAddress"
            :rules="rules.emailAddress"
            required
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-5">
          <label class="d-inline-block text-subtitle-2 gray--text text--darken-2 mb-2">
            {{ $t("createOrder.visualization.contactDetails.form.phoneNumber.label") }}
          </label>
          <v-text-field
            single-line
            v-mask="'###-###-####'"
            v-model="form.phoneNumber"
            :rules="rules.phoneNumber"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            large
            type="submit"
            elevation="0"
            color="primary"
            class="rounded-0 w-100"
            :loading="submitting"
            :disabled="!formIsFilled || !formIsValid"
          >
            {{ $t("createOrder.visualization.contactDetails.actions.seeDesign") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      formIsValid: false,
      submitting: false,
      form: {
        fullName: null,
        emailAddress: null,
        phoneNumber: null
      },
      rules: {
        fullName: [v => !!v || this.$t("createOrder.visualization.contactDetails.form.fullName.rules.required")],
        emailAddress: [
          v => !!v || this.$t("createOrder.visualization.contactDetails.form.emailAddress.rules.required"),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("createOrder.visualization.contactDetails.form.emailAddress.rules.format")
        ],
        phoneNumber: [
          v => {
            if (!v) return true;
            return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(v) || this.$t("createOrder.visualization.contactDetails.form.phoneNumber.rules.format");
          }
        ]
      }
    };
  },
  computed: {
    formIsFilled() {
      return this.form.fullName && this.form.emailAddress;
    }
  },
  methods: {
    ...mapActions("Customer", ["saveCustomerInfo"]),
    async handleFormSubmit() {
      this.submitting = true;
      const isValid = this.$refs.contactDetailsForm.validate();
      if (isValid) {
        try {
          await window.API.createCustomer(this.form);
        } finally {
          this.submitting = false;
          this.saveCustomerInfo(this.form);
        }
      }
    }
  }
};
</script>
