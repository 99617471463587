import { i18n } from "@/plugins/i18n";

const state = {
  designSteps: [
    {
      label: "",
      name: "kitchenLayout/kitchenShape",
      path: "/design-countertop/kitchen-layout/kitchen-shape",
      completed: false,
      showInStepper: false,
      stepperIndex: 1
    },
    {
      label: i18n.t("createOrder.kitchenLayout.stepTitle"),
      name: "kitchenLayout/sqft",
      path: "/design-countertop/kitchen-layout/sqft",
      completed: false,
      showInStepper: true,
      stepperIndex: 1
    },
    {
      label: "",
      name: "colors/floor",
      path: "/design-countertop/colors/floor",
      completed: false,
      showInStepper: false,
      stepperIndex: 2
    },
    {
      label: i18n.t("createOrder.colors.stepTitle"),
      name: "colors/cabinet",
      path: "/design-countertop/colors/cabinet",
      completed: false,
      showInStepper: true,
      stepperIndex: 2
    },
    {
      label: i18n.t("createOrder.visualization.stepTitle"),
      name: "visualization",
      path: "/design-countertop/visualization",
      completed: false,
      showInStepper: true,
      stepperIndex: 3
    }
  ],
  orderSteps: [
    {
      label: i18n.t("createOrder.kitchenDetails.stepTitle"),
      name: "kitchenDetails",
      path: "/order-countertop/kitchen-details",
      completed: false
    },
    {
      label: i18n.t("createOrder.address.stepTitle"),
      name: "address",
      path: "/order-countertop/address",
      completed: false
    },
    {
      label: i18n.t("createOrder.deposit.stepTitle"),
      name: "deposit",
      path: "/order-countertop/deposit",
      completed: false
    }
  ]
};

const getters = {};

const actions = {
  completeStep({ commit }, name) {
    commit("MARK_STEP_AS_COMPLETED", { name });
  }
};

const mutations = {
  MARK_STEP_AS_COMPLETED(state, payload) {
    const items = [...state.designSteps, ...state.orderSteps];
    const step = items.find(item => item.name === payload.name);
    step.completed = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
